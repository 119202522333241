import React from "react";

export default ({ className, name, label, required, type, onChange }) => (
  <div className={className || "w-full md:w-1/2"}>
    <label for={name} className="field-label">
      {label}
    </label>
    <input
      type={type || "text"}
      id={name}
      name={name}
      required={!!required}
      className="field-text"
      onChange={onChange}
    />
  </div>
);
