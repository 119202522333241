import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import Content, { HTMLContent } from "../components/Content";
import useSiteMetadata from "../components/SiteMetadata";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import { Helmet } from "react-helmet";
import logo from "../img/signature-solid-navy.svg";

import DownloadForm from "../components/forms/DownloadForm";

export const DownloadPageTemplate = ({
  content,
  contentComponent,
  description,
  previewImage,
  formName,
  formCta,
  downloadLink,
  subtitle,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <>
      <section class="container mt-2 md:mt-4 lg:mt-8 space-y-4">
        <div className="columns">
          <div className="w-full lg:w-10/12 lg:pr-12">
            <h2 className="font-display font-bold text-3xl lg:text-4xl text-accent-m1 leading-tight">
              {subtitle}
            </h2>
          </div>
        </div>

        <div class="columns">
          <div class="w-full lg:w-10/12">
            {previewImage && (
              <figure className="sm:float-right w-3/4 mx-auto sm:w-1/3 lg:w-5/12 md:-mt-4 lg:-mt-6 sm:ml-8 mb-8">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: previewImage,
                    alt: description,
                  }}
                />
              </figure>
            )}

            <PostContent
              content={content}
              className="prose lg:prose-lg max-w-none"
            />
          </div>
        </div>
      </section>

      {!!formName ? (
        <DownloadForm name={formName} cta={formCta} file={downloadLink} />
      ) : (
        // Skip the form if we just want to show a download button !
        <section class="container text-center">
          <a
            href={downloadLink}
            className="btn btn-accent text-lg lg:text-2xl px-4 lg:px-8 mx-auto btn-avenir-semibold text-white"
          >
            {formCta}
          </a>
        </section>
      )}
    </>
  );
};

const DownloadPage = ({ data }) => {
  const { markdownRemark: post } = data;

  const { siteUrl } = useSiteMetadata();

  return (
    <Layout
      headerClassName="bg-accent curve-br"
      navbarLogo={logo}
      className={post.frontmatter.accentClass}
      headerTitle={post.frontmatter.heading}
    >
      <Helmet titleTemplate="%s - ScriptDrop">
        <title>{`${post.frontmatter.title}`}</title>
        <meta property="og:title" content={post.frontmatter.title} />
        <meta
          property="og:description"
          content={post.frontmatter.description}
        />
        {post.frontmatter.shareImage && (
          <meta
            property="og:image"
            content={siteUrl.concat(
              post.frontmatter.shareImage.childImageSharp
                ? post.frontmatter.shareImage.childImageSharp.shareImage.src
                : post.frontmatter.shareImage.publicURL
            )}
          />
        )}
        <meta property="description" content={post.frontmatter.description} />
      </Helmet>

      <DownloadPageTemplate
        contentComponent={HTMLContent}
        content={post.html}
        {...post.frontmatter}
      />
    </Layout>
  );
};

export default DownloadPage;

export const pageQuery = graphql`
  query downloadPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        heading
        subtitle
        accentClass
        formCta
        formName
        downloadLink
        previewImage {
          childImageSharp {
            fluid(maxWidth: 500, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        shareImage {
          publicURL
          childImageSharp {
            shareImage: resize(width: 1200, height: 630, fit: COVER) {
              src
            }
          }
        }
      }
    }
  }
`;
