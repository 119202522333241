import React, { useState } from "react";
import NetlifyForm from "./NetlifyForm";
import FieldControl from "./FieldControl";
import SubmitControl from "./SubmitControl";
import "whatwg-fetch";

export default ({ name, file, cta }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [downloadReady, setDownloadReady] = useState(false);
  const [_error, setError] = useState(false);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const encode = (formData) =>
    Object.keys(formData)
      .map(
        (key) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(formData[key])
      )
      .join("&");

  const handleSubmit = (e) => {
    setLoading(true);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": name, ...data }),
    })
      .then(() => setDownloadReady(true))
      .catch(() => setError(true))
      .finally(() => setLoading(false));

    e.preventDefault();
  };

  return (
    <section className="container">
      <div className="lg:w-10/12 mx-auto rounded-xl p-4 sm:p-6 lg:p-8 xl:p-10 bg-navy-p3">
        {downloadReady ? (
          <div className="text-center my-4 md:my-6">
            <p className="text-accent-m2 text-center font-semibold text-lg lg:text-3xl pb-4 lg:pb-8">
              Thank you!
            </p>
            <a
              href={file}
              className="btn btn-accent text-lg lg:text-2xl px-4 lg:px-8 btn-avenir-semibold text-white"
            >
              Download File
            </a>
          </div>
        ) : (
          <NetlifyForm
            name={name}
            action="/download-form"
            onSubmit={handleSubmit}
          >
            <p className="text-accent-m2 font-semibold text-lg lg:text-2xl pb-4 lg:pb-8">
              {cta}
            </p>

            <div className="columns">
              <FieldControl
                onChange={handleChange}
                name="first_name"
                label="First Name*"
                required={true}
              />
              <FieldControl
                onChange={handleChange}
                name="last_name"
                label="Last Name*"
                required={true}
              />
              <FieldControl
                onChange={handleChange}
                name="person_title"
                label="Title"
              />

              <FieldControl
                onChange={handleChange}
                name="Company"
                label="Company*"
                required={true}
              />
              <FieldControl
                onChange={handleChange}
                name="email"
                type="email"
                label="Email*"
                required={true}
              />
            </div>
            <SubmitControl disabled={loading} value="Download" />
          </NetlifyForm>
        )}
      </div>
    </section>
  );
};
