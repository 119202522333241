import React from "react";

export default ({ name, action, className, children, onSubmit }) => (
  <form
    className={className}
    method="POST"
    name={name}
    action={action}
    data-netlify="true"
    onSubmit={onSubmit}
  >
    <input type="hidden" name="form-name" value={name} />
    {children}
  </form>
);
