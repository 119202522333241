import React from "react";

export default (props) => (
  <div className="pt-8 md:pt-12 md:pb-6 flex md:justify-center">
    <input
      type="submit"
      className="btn md:px-5 py-2 md:py-3 text-lg md:text-xl w-full md:w-1/3 btn-avenir-semibold btn-accent"
      {...props}
    />
  </div>
);
